<template>
  <a-dropdown
    :trigger="['click']"
    placement="bottomRight"
    @visibleChange="handleVisibleChange"
  >
    <a-tooltip placement="bottom" title="Notifications">
      <a-badge :count="notificationCount" :overflow-count="99">
        <a-button shape="circle" icon="bell" />
      </a-badge>
    </a-tooltip>
    <template slot="overlay">
      <a-menu class="notification-menu">
        <a-menu-item class="notification-header">
          <div
            class="d-flex justify-content-between align-items-center w-100"
            :class="{ 'flex-row-reverse': $i18n.locale === 'ar-AR' }"
          >
            <div
              class="d-flex align-items-center"
              :class="{ 'flex-row-reverse': $i18n.locale === 'ar-AR' }"
            >
              <a-icon
                type="bell"
                theme="filled"
                class="header-icon"
                :class="$i18n.locale === 'ar-AR' ? 'ml-2' : 'mr-2'"
              />
              <span class="font-weight-bold">{{
                $t("topBar.notification.notifications")
              }}</span>
            </div>
            <a
              @click.stop="viewAllNotifications"
              class="view-all-link"
              :class="{ 'flex-row-reverse': $i18n.locale === 'ar-AR' }"
            >
              <span class="text-primary">{{
                $t("topBar.notification.readMore")
              }}</span>
              <a-icon
                :type="$i18n.locale === 'ar-AR' ? 'arrow-left' : 'arrow-right'"
                :class="[
                  $i18n.locale === 'ar-AR' ? 'mr-1' : 'ml-1',
                  'text-primary',
                ]"
              />
            </a>
          </div>
        </a-menu-item>
        <a-menu-item
          v-for="notification in notifications"
          :key="notification._id"
          @click="handleNotificationClick(notification)"
        >
          <div class="notification-item">
            <div class="flex-grow-1">
              <div
                class="d-flex justify-content-between align-items-center"
                :class="{ 'flex-row-reverse': $i18n.locale === 'ar-AR' }"
              >
                <div
                  class="d-flex align-items-center"
                  :class="{ 'flex-row-reverse': $i18n.locale === 'ar-AR' }"
                >
                  <a-icon
                    type="message"
                    :class="$i18n.locale === 'ar-AR' ? 'ml-2' : 'mr-2'"
                  />
                  <div class="notification-title">
                    {{ notification.topic }}
                  </div>
                </div>
                <div class="notification-time text-muted">
                  {{ moment(notification.createdAt).fromNow() }}
                </div>
              </div>
              <div class="notification-description" dir="rtl">
                {{ notification.message }}
              </div>
            </div>
          </div>
        </a-menu-item>
        <a-menu-item v-if="!notifications.length" disabled>
          <div class="empty-notifications text-center">
            <a-icon type="inbox" class="mr-2 empty-inbox-icon" />
            {{ $t("topBar.notification.noNotifications") }}
          </div>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import apiClient from "@/services/axios";
import moment from "moment";
import JwtDecode from "jwt-decode";
import { mapState } from "vuex";

export default {
  name: "CuiNotificationMenu",
  data() {
    return {
      notificationCount: 0,
      notifications: [],
      userId: null,
      titleLoop: null,
      test: false,
      changeTitle: false,
    };
  },
  computed: {
    ...mapState(["settings"]),
  },
  created() {
    const token = localStorage.getItem("accessToken");
    if (token) {
      const decoded = JwtDecode(token);
      this.userId = decoded.id;
    }
    this.fetchNotifications();
    this.$socket.client.on("studentNameChange", this.newNotifyNotification);
    this.$socket.client.on(
      "notifyManagerNewComment",
      this.newNotifyNotification
    );
    this.$socket.client.on("notifyManagerNewLike", this.newNotifyNotification);
    // Listen for notifications-read event
    this.$root.$on("notifications-read", this.resetNotification);
  },
  beforeDestroy() {
    this.$socket.client.off("studentNameChange");
    this.$socket.client.off("notifyManagerNewComment");
    this.$socket.client.off("notifyManagerNewLike");
    this.$root.$off("notifications-read", this.resetNotification);
  },
  methods: {
    moment,
    async fetchNotifications() {
      try {
        const response = await apiClient.get("/notifications");
        this.notificationCount = response.data.unreadCount;
        this.notifications = response.data.notifications
          .slice(0, 5) // Take only first 5 items
          .map((item) => ({
            ...item,
            link: this.getNotificationLink(item),
          }));
      } catch (error) {
        this.$message.error(this.$t("topBar.notification.errorFetch"));
      }
    },
    getNotificationLink(notif) {
      switch (notif.refType) {
        case "studentNameChange":
          return "/eleve/" + notif.ref;
        case "notifyManagerNewComment":
          return "/actuality";
        case "notifyManagerNewLike":
          return "/actuality";
        default:
          return "/";
      }
    },
    handleNotificationClick(notification) {
      if (notification.link !== "/" && notification.link !== this.$route.path) {
        // Force reload
        if (this.$route.path.startsWith("/eleve/")) {
          this.$router.replace("/").then(() => {
            this.$router.push(notification.link);
          });
        } else {
          this.$router.push(notification.link);
        }
      }
    },
    viewAllNotifications() {
      const notificationsPath = "/notifications";
      if (this.$route.path !== notificationsPath) {
        this.$router.push(notificationsPath);
      }
    },
    handleVisibleChange(visible) {
      if (visible) {
        this.resetNotification();
      }
    },
    async resetNotification() {
      try {
        await apiClient.post("/notifications/mark-as-read", {});
        this.notificationCount = 0;
        if (this.changeTitle) {
          clearInterval(this.titleLoop);
          document.title = "Système de gestion";
          this.changeTitle = false;
        }
      } catch (error) {
        console.error("Failed to mark notifications as read:", error);
      }
    },
    newNotifyNotification(data, db) {
      if (
        this.userId &&
        data.users.includes(this.userId) &&
        db &&
        this.settings.activeBuilding === db
      ) {
        this.notificationCount += 1;
        this.changeTitle = true;
        clearInterval(this.titleLoop);
        this.titleLoop = setInterval(() => {
          if (this.test) {
            document.title = "Système de gestion";
          } else {
            document.title =
              "(" + this.notificationCount + ") " + "Notifications";
          }
          this.test = !this.test;
        }, 1500);
        this.fetchNotifications();
      }
    },
  },
};
</script>

<style>
.notification-menu {
  width: 370px;
  max-width: 370px;
}

.ant-dropdown-menu {
  max-height: 400px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.notification-menu .notification-header {
  padding: 12px 16px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 4px;
  cursor: default !important;
}

.notification-menu .notification-header:hover {
  background: transparent !important;
}

.notification-header .view-all-link {
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
}

.notification-header .view-all-link:hover {
  text-decoration: underline;
}

.notification-header .view-all-link .anticon {
  font-size: 12px;
}

.notification-menu .notification-item {
  padding: 12px 16px;
}

.notification-menu .notification-item .anticon {
  font-size: 16px;
}

.notification-menu .notification-item .notification-title {
  font-weight: 700;
  margin-right: 8px;
}

.notification-menu .notification-item .notification-description {
  font-size: 13px;
  color: #a1a1a1;
  padding-left: 24px;
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 320px;
}

.notification-menu .notification-item .notification-time {
  font-size: 12px;
  color: #8c8c8c;
  white-space: nowrap;
}

.notification-menu .empty-notifications {
  padding: 24px 16px;
  text-align: center;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notification-menu .empty-notifications .empty-inbox-icon {
  font-size: 20px;
}
.flex-grow-1 {
  flex-grow: 1;
}

[dir="rtl"] .notification-header {
  text-align: right;
}

[dir="rtl"] .notification-description {
  text-align: right;
}
</style>
