import localeAntd from "ant-design-vue/lib/locale-provider/en_US";

const messages = {
  all: {
    okay: "Okay",
    add: "Add",
    edit: "Edit",
    cancel: "Cancel",
    save: "Save",
    sureToCancelAlert: "Are you sure to cancel?",
    sureToDelete: "Are you sure to delete?",
    startDate: "Start date",
    endDate: "End date",
    requiredStartDate: "Start date is required field",
    requiredEndDate: "End date is required field",
    requiredName: "Name is required field",
    requiredLevel: "Level is required field",
    enterInformationBelow: "Enter the information in the fields below",
    oui: "Yes",
    non: "No",
    and: "And",
    filterBy: "Filter by",
    filterByAddedDate: "Filter by added date",
    sureTo: "Are you sure?",
    unsavedchanges: "Unsaved changes!",
    unsavedchangesContent:
      "Do you really want to leave? You have unsaved changes!",
    savedChanges: "The modifications have been saved successfully",
    typeNeeded: "Allowed file types: png, jpg, jpeg.",
    typeNeededPNG: "Allowed file types: png.",
    infoConx: "Login Information",
    lienConx: "Login Link",
    lienConxText: "or EdTeacher Mobile App",
  },

  teacherAvailability: {
    available: "Available",
    notAvailable: "Not available",
    saved: "The availability has been saved successfully",
    notFilledYet: "The teacher has not yet filled the availability sheet",
  },

  smartSchedule: {
    generalParams: "General parameters",
    comment: "Comment",
    commentRequired: "Comment is required field",
    startDay: "Start day",
    endDay: "End day",
    workingTime: "Working time",
    breakingTime: "Breaking time",
    startAt: "Start at",
    endAt: "End at",
    scheduleParmsAdded: "The schedule have been added successfully",
    generalParamsSaved: "The general parameters have been saved successfully",
    selectSchedule: "Select the settings",
    subjectsSchedParam: "The subjects",
    weeklyRate: "Weekly rate",
    module: "Module",
    subject: "Subject",
    moduleSpecific: "Pilote module",
    teacherClassParam: "Teacher assignment",
    teacherAffectedSuccess: "The teacher has been assigned successfully",
    teachersWeeklyRate: "Teachers weekly rate",
    name: "Name of the setting",
    smartSchedule: "Smart Schedule",
    smartSchedules: "Smart Schedules",
    ready: "Ready",
    pending: "Pending",
    finished: "Finished",
    error: "Error",
    addParam: "Add a setting",
    migrateSuccess: "The schedules has been migrated successfully",
    migrate: "Migrate the schedules",
    unmigrated: "The schedules have been successfully restored",
    noDataToUnmigrate: "The schedules already contain manual sessions!",
    unmigrate: "Restore manual sessions",
    minDuration: "Minimum duration",
    maxDuration: "Maximum duration",
    fillAllClassroomsToGenerate:
      "Please fill all classrooms to generate the schedules",
    weeklyRateMaxError:
      "The weekly rate should be equal to or greater than the maximum duration",
    weeklyRateMinError:
      "The weekly rate should be equal to or greater than the minimum duration",
  },

  calender: {
    month: "Month",
    week: "Week",
    day: "Day",
    addEvent: "Add event",
    addEventTitle: "Add a new event",
    duration: "Duration",
    durationRequired: "Duration is required field",
    exam: "Exam",
    party: "Party",
    excursion: "Excursion",
    meeting: "Meeting",
    other: "Other",
    eventType: "Event type",
    color: "Color",
    typeRequired: "Event type is required field",
    editEventTitle: "Edit event",
    editEvent: "Edit event",
    editedEvent: "The event has been edited successfully",
    deletedEvent: "The event has been deleted successfully",
    addedEvent: "The event has been added successfully",
    meetingBetweenTeachersAdvisors: "Meeting between teachers and advisors",
    createdBy: "Created by the advisor",
  },

  chat: {
    ROOMS_EMPTY: "No rooms",
    ROOM_EMPTY: "No room selected",
    NEW_MESSAGES: "New Messages",
    MESSAGE_DELETED: "This message was deleted",
    MESSAGES_EMPTY: "No messages",
    CONVERSATION_STARTED: "Conversation started on:",
    TYPE_MESSAGE: "Type message",
    SEARCH: "Search",
    IS_ONLINE: "is online",
    LAST_SEEN: "last seen ",

    parentDetails: "Parent details",
    startNewConversationWithParent: "Create new conversation with parent",
    addParent: "Add parent",
    deleted: "Deleted",
  },

  paymentStatus: {
    paymentStatus: "Payment status",
    paidAmount: "The amount paid",
    monthRemainingPayments: "{month} remaining payments",
    allRemainingPayments: "All remaining payments",
    noRemainingPyaments: "There are no remaining payments",
    nothingPaid: "There are no payments paid",
  },

  language: {
    ar: "Arabic",
    fr: "French",
    en: "English",
  },

  appreciation: {
    adminAppreciation: "Admin appreciation",
    withOptions: "Specified appreciations",
    manual: "Manual Appreciations",
    appreciation: "Appreciation",
    add: "Add appreciation",
    changedToManual: "The appreciation mode changed to manual",
    changedToOptions: "The appreciation mode changed to specified",
    teacherManualDesc:
      "The teacher has access to write anything in appreciation",
    saved: "The appreciation has been saved successfully",
    deleted: "L'appréciation a été supprimée avec succès",
  },

  feedback: {
    sendFeedback: "Send a feedback",
    category: "Category",
    comment: "Comment",
    problem: "Problem/Bug",
    idea: "Idea For Improvement",
    others: "Others",
    section: "Section",
    selectSection: "Please select the section",
    priority: "Priority",
    selectPriority: "Please choose the priority",
    low: "Low",
    medium: "Medium",
    high: "High",
    critical: "Critical",
    message: "Message",
    uploadImages: "Upload images",
    uploadAudio: "Upload audio",
    record: "Record",
    successSent:
      "Thank you for your contribution. We'll review it and get back to you if needed. Have a great day!",
  },

  certification: {
    add: "Add certification",
    moyPer: "Personalized Moyenne",
    moySpec: "Specific Moyenne",
    default: "Set the default certificates",
    confirmDefault:
      "This action will remove your certifications. Are you sure to set it as default?",
    name: "Certificate name",
    min: "Minimum average",
    max: "Maximum average",
    minError: "The minimum average cannot be greater than the maximum average",
    maxError: "The maximum average cannot be lower than the minimum average",
    defaultDone: "The certificates have been updated successfully",
    moyenne: "Grade",
  },

  decisionConseil: {
    decision: "Decision",
    decisionM: "Decision (Male)",
    decisionF: "Decision (Female)",
    decisionConseil: "Board decisions",
    default: "Use default decisions",
    defaultDone: "Board decisions have been successfully updated",
    confirmDefault:
      "This action will remove your decisions from the board. Are you sure you set it as default?",
  },

  notifications: {
    desactivation:
      "Account has been suspended! Contact the administration for more information!",
    desactivateBuilding:
      "Your establishment has been suspended! Contact the administration for more information!",
    updateuser: "Account Modified! please reconnect!",
    updateAccess: "Modified Access! please reconnect!",
  },

  login: {
    incorrectInfo: "The username or password is incorrect",
    bienvenue: "Welcome to",
    title: "Management system for schools",
    connectezVous: "Log in!",
    connextion: "Login",
    requisMdp: "Please insert your password!",
    requisUsername: "Please enter your username!",
    username: "Username",
    password: "Password",
    connecte: "Connected",
    loginSuccess: "You have successfully logged in",
    readAndAccept: "I have read and accept the",
    termsAndConditions: "Terms and Conditions",
    subscribePart1:
      "If your school is not yet registered, discover our exclusive offers on our ",
    subscribePart2: "website ",
    subscribePart3: "and join our educational community today!",
  },

  niveau: {
    niveauPre: "Préscolaire",

    niveau0: "Preparatory",
    niveau1: "1st year",
    niveau2: "2nd year",
    niveau3: "3rd year",
    niveau4: "4th year",
    niveau5: "5th year",
    niveau6: "6th year",
    autres: "Others",
    //college
    niveau7: "7 ème année",
    niveau8: "8 ème année",
    niveau9: "9 ème année",
    //lycee
    niveau10: "1 ère année secondaire",
    niveau11: "2 ème année secondaire",
    niveau12: "3 ème année secondaire",
    niveau13: "4 ème année secondaire",
    autres: "Autres",
  },

  assiduite: {
    assiduite: "Attendance",
    retard: "Delay",
    present: "Present",
    absence: "Absence",
    assiduiteMSA: "Attendance has been updated",
    assiduiteMoisChart: "Class attendance this month { className }",
    nb_absent: "Number of absences",
    nb_delay: "Number of delays",
    date: "Date",
    areYouSureToEdit: "Are you sure you want to modify the attendance?",
  },

  avance: {
    ajoutAvance: "Add an advance",
    ajoutAvanceSubTitle: "Please complete the fields below to add an advance.",
    montantAvance: "Advance amount",
    impoAnnulerAvance: "Unable to cancel advance",
    typeRecetteRequis: "Recipe type is required!",
    saisirChequeInfo: "Enter check details",
    saisirChequeEspaceInfo: "Enter check and cash information",
    chequeNumRequis: "The check number is required!",
    historiqueAvances: "Advance history",
    avanceAjoute: "Advance successfully added",
  },

  profil: {
    parentSuggestionTitle: "Parents who have the same phone number",
    historique: "History",
    paiement: "Payment",
    payes: "Paid",
    nonPayes: "Not paid",
    bulletin: " School report ",
    qrCode: "Qr code",
    informations: "Informations",
    Control: "Parental Control ",
    paiementScol: "School payment",
    paiementAct: "Activity payment",
    listeReglements: "List of regulations",
    sexe: "Sex",
    nom: "Last Name ",
    prenom: "First Name",
    fullname: "Full Name",
    dateNaissance: "Date of birth",
    garcon: "Boy",
    fille: "Girl",
    adresse: "Address",
    tel: "Telephone",
    classe: "Class",
    notes: "Grades",
    nonInscrit: "Not yet registered",
    nomUser: "Username",
    mdp: "Password",
    gere: "Manage",
    consulter: "Consult",
    consulterPaiement: "Consulter Payment",
    etat: "State",
    mois: "Month",
    etablissement: "Establishment",
    administration: "Administration",
    ajouteLe: "Add in",
    lastModifi: "Last modification",
    annulerInscri: "Cancel registration",
    changerPrametreParent: "Change parent settings",
    changerPrametreEleve: "Change student settings",
    inscriActivitePour: "Activity registration for {name}",

    poid: "Weight",
    taille: "Height",
    maladies: "Illness",
    remarques: "Remarks",
    selectOption: "At least select one option",
    aucuneMaladieEnregistree: "No illness was recorded",
    aucuneDossierMedical: "No medical records found!",

    annulationEleve: "Cancel registration",
    deleteEleve: "Remove Student",
    nameChangeRequest: "Parent requested name changes on",
    firstNameChangeRequest: "First name change from",
    lastNameChangeRequest: "Last name change from",
    to: "To",
    approuver: "Approve",
    rejeter: "Reject",
  },
  topBar: {
    issuesHistory: "Issues History",
    projectManagement: "Project Management",
    typeToSearch: "Search...",
    findPages: "Find pages...",
    actions: "Acts",
    status: "Status",
    profileMenu: {
      hello: "Hello",
      billingPlan: "Billing Plan",
      role: "Role",
      email: "Email",
      phone: "Phone",
      editProfile: "Edit Profile",
      logout: "Log - Out",
    },
    notification: {
      notifications: "Notifications",
      searchPlaceholder: "Search notifications...",
      noNotifications: "No notifications found",
      errorFetch: "Failed to load notifications",
      readMore: "Read more",
    },
  },

  menu: {
    accessTeacher: "Teacher access",
    parametrageEtablissement: "Establishment settings",
    parametrageComptabilite: "Accounting settings",
    parametrageScolarite: "Schooling settings",
    smartSchedule: "Smart Schedule",
    teacherAvailability: "Teacher Availability",
    calender: "Calender",
    supervision: "Supervision",
    ptchat: "Chat parents with teachers",
    chat: "Chat",
    cheques: "The checks",
    logout: "Logout",
    activites: "Activities",
    etablissement: "Establishment",
    generale: "Genral",
    accueil: "Home",
    parametrage: "Settings",
    actualite: "Actuality",
    administration: "Administration",
    autorisation: "Permission",
    utilisateur: "User",
    liste: "Students list",
    activites: "Activities",
    cantine: "Canteen",
    etatPaiement: "State of payments",
    inscriActiv: "Registration: Activities",
    scolarite: "Schooling",
    inscriScol: "Registration : Education",
    etatPaiements: "State of payments",
    listePunition: "List of punishments",
    emploi: "Timetable",
    eleves: "Students",
    enseignants: "Teachers",
    bulletin: "School report",
    bulletinStatistique: "Result statistics",
    notes: "Grades",
    notesModule: "Grades by subject",
    notesParEleve: "Student grades",
    notesModuleComplet: "Grades by module",
    personnel: "Personal",
    listePersonnels: "List of staff",
    listeEnseignants: "List of teachers ",
    listeActivites: "List of activities",
    virementBancaire: "Bank Transfer",
    listeEleves: "Students list",
    comptabilite: "Accounting",
    etatRecettes: "Revenue state",
    journaleCaisse: "Cash journal",
    depenses: "Expenses",
    depensesPersonnel: "Wage expense",

    etatImpayes: "State of unpaid",
    listeReglement: "Regulation List",
    reglementsAnnules: "Réglements annulés",
    etatImpayesActivites: "Unpaid status: Activities",
    etatImpayesScolarite: "paid status: Education",
    declaration: "Declaration",
    declarationTva: " TVA Declaration",
    chiffreAffaires: "Turnovers",
    chiffreAffairesScolarite: "Turnover : Education",
    statistique: "Statistics",
    actulite: "News",
    sms: "SMS",
    envoyerSms: "Send SMS",
    envoyerSmsLogin: "Parent Logins",

    envoyerParClasseSms: "Send by class",
    historiqueSms: "History",
    caisseAvance: "Cash advance",
    assiduite: "Attendance",
    preinscription: "Pre-registration",

    profile: "My Profile",

    certificate: "Certificate",
  },

  accueil: {
    activite: "Activity",
    eleves: "Students",
    classes: "Classes",
    serviceActiv: "Services / Activities",
    benefice: "Total profit",
    montant: "Amount",
    pourcentage: "Percentage",
    depense: " Total Expense",
    RevenuDep: "Expenses / Income ",
    depenses: "Expenses",
    revenu: "Income",
    tnd: "TND",
  },

  sms: {
    paymentRappel: "Payment reminder",
    deatilsAutoSmsTitle: "Details of Automated SMS Messages",
    deatilsAutoPnTitle: "Details of Automated mobile notifications",
    deatilsAutoSms: "More details",
    newPayment: "New payment",
    newInscription: "New registration",
    newAbsence: "Absence/Delay",
    newCourse: "Course/Exercise",
    bulletinDispo: "Report card available",
    actualite: "News",
    autoSms: "Automatic SMS settings",
    autoPn: "Automatic mobile notifications settings",
    votreSolde: "YOUR BALANCE",
    nbrSmsRestant: "Number of SMS remaining",
    nomlExpediteur: "SENDER'S NAME",
    desclExpediteur: "The SMS are sent as",
    gererListeDistinateurs: "MANAGE THE LIST OF DESTINATIONS",
    envoyerDesSms: "SEND SMSs",
    listeVosEleves: "List of your students",
    listeClasses: "Class list",
    selectionnerEleves: "Select students",
    selectionnerClases: "Select Classes",
    pressEnterToSelect: "Press enter to select",
    entrerNumerosSpecifiques: "Enter specific numbers",
    entrerNumerosSpecifiquesDesc: "Phone numbers must be separated by commas",
    categorieTag: "Category/tag",
    categorieTagDesc: "Tags help you organize your posts well",
    saisirCategorieTag: "Enter categories/tags",
    contenueVotreMessage: "Enter the content of your message",
    contenueMessage: "Enter the content of the SMS",
    listeFinale: "FINAL LIST",
    nombreSms: "Number of SMS",
    nombreMessage: "Number of messages",
    nomberTotal: "Total number",
    nombreMessageParCategorie: "Number of SMS per category",
    nombreCaracteres: "NUMBER OF CHARACTERS",
    pere: "Father",
    mere: "Mother",
    specifique: "Specific",
    destinataire: "Receiver",
    telephone: "Telephone",
    parents: "parents",
    personnel: "personal",
    sanction: "sanction",
    bulletin: "Report",
    envoyer: "Send",
    nonInscrit: "Not enrolled",
    noOptions: "The list is empty",
    jours: "Days",
    boiteEnvoieSms: "SMS send box",
    message: "Message",
    numsSpecifiques: "Specific numbers",
    listeParents: "List of parents",
    envoyeParUser: "Sent by user",
    groupe: "Group",
    tags: "Tags",
    envoiDate: "Sent date",
    selectAll: "Select all",
    //send logins
    envoyerDesSmsLogins: "SEND SMS OF LOGINS",
    alertNumberSMS:
      "If the username and password are long (exceeds 22 characters) the number of SMS for a parent can consume 2 SMS of the balance",
  },

  action: {
    reinitialiser: "Reset",
    clone: "Clone",
    simpleBadge: "Simple badge",
    badgeAvecPhoto: "Badge with photo",
    reset: "Reset",
    imprimerTab: "Print table",
    imprimerBadges: "Print the badges",
    imprimerNewBadges: "Imprimer les badges with",
    actions: "Actions",
    supprimer: "Delete",
    ajouter: "Add",
    modifier: "Edit",
    consulter: "Consult",
    annuler: "Cancel",
    enregistrer: "Save",
    afficher: "Show",
    voir: "See",
    desactiver: "Disable",
    activer: "Enable",
    imprimer: "Print",
    confirmer: "Confirm",
    changer: "Change",
    importer: "Import",
    preview: "Preview",
    clone: "Clone",
  },

  actualite: {
    ajouterAlbum: "Add an album",
    ajouterActualite: "Add news",
    date: "Date release",

    tousClasses: "Classes",
    titre: "Title",
    categorie: "Category",
    cibles: "Targets",
    jaimes: "Like",
    photos: "Photos",
    dateCreation: "Creation Date",
    tous: "All",
    enseignants: "Teachers",
    eleves: "Students",
    teachers: "Teachers",
    students: "Students",
    administration: "Administration",
    parents: "Parents",
    cliquer: "Click or drag the file to the area to upload it",
    support:
      "Support single or batch download. Strictly prohibit downloading company data or other group files",
    description: "Description",
    ajouterPub: "Add a publication",
    trimestre1: "Trimester 1",
    trimestre2: "Trimester 2",
    trimestre3: "Trimester 3",
    trimester_one: "Original Trimester",
    trimester_two: "Cloned Trimester",
    retour: "Return",
    pasImages: "No images",
    mentionJaimes: "Likes",
    modifierImages: "Edit images",
    specificFor: "Specific for",
    levels: "Levels",
    classrooms: "Classrooms",
    students: "Students",
    specified: "Specified",
    specifiedInfo: "Specified filters will apply only for parents",
  },

  etablissement: {
    reductionType: "Type of reduction",
    pourcentage: "Percentage",
    dinar: "Dinar",
    access: "Access",
    editAccess: "Edit access",
    accessUpdated: "Access updated successfully",
    editingAccess: "Editing access",
    config: "Configurations",
    editConfig: "Edit Configuration",
    accessConfig: "Configuration has been successfully modified",
    editingConfig: "Editing Configuration",
    alertDisableChat:
      "Enable or disable chat between parents and teachers (if disabled, teachers and parents will instantly lose access to chat in the mobile and web apps)",
    etablissements: "Establishments",
    classe: "Class",
    classes: "Classrooms",
    salles: "Rooms",
    salle: "Room",
    services: "Services",
    activites: "Activities",
    typeQualification: " Qualifications Type",
    anneeScolaire: "School year",
    logo: "Logo",
    nom: "Name",
    designation: "designation",
    directrice: "Director",
    dateEnregistrement: "Registration Date",
    telephone: "Telephone",
    adresse: "Adress",
    type: "Type",
    niveau: "Level",
    fraisInscri: "Inscription Fees",
    fraisMensuel: "Monthly fees",
    emploi: "Timetable",
    dateCreation: "Creation Date",
    capacite: "Capacity",
    fournisseurs: "Providers",
    fournisseur: "Provider",
    fournisseurNonRequis: "Provider (not required) :",
    service: "Service",
    responsable: "Responsible",
    responsableActivite: "Activity responsible",
    ordrePriorite: "Order/priority",
    order: "Order",
    dateDebut: "Start date",
    dateFin: "End date",
    seuleFois: "A single time",
    entrerInfo: "Enter the information in the fields below",
    modifierEmploi: "Edit Timetable for {name}",
    other: "Other",
    editAlert:
      "La modification de l'année scolaire n'engendre pas une modification sur les mois d'inscription des élèves déjà inscrits, vos étes sûrs de modifier ?",
    clickOrDragToUpload: "Click or drag a file to this area to upload it",
    clickForUploadLogo: "Click to edit logo",

    ecole: "Primary school",
    college: "Middle school",
    lycee: "High school",
    collegeEtLycee: "Middle & High school",
    primaireEtCollege: "Primary & Middle school",
    jardinEnfant: "Kindergarten",
    cache: "Stamp",
    choisir: "Choose",
    matriculeFiscale: "Tax registration number",
    ArabicName: "Name in Arabic",
    delegeRegional: "Regional Delegate",
  },

  comptabilite: {
    comptabilite: "Accounting",
    fournisseur: "Provider",
    typeDepense: "Expense Type",
    typeRecette: "Revenue",
    nom: "Name",
    adresse: "Adress",
    telephone: "Telephone",
    email: "E-mail",
    codeTVA: "TVA Code ",
  },
  scolarite: {
    affichageParametrage: "Display settings",
    matieres: "Subjects",
    modules: "Modules",
    modulesSpec: "Specific modules",
    nomModule: "Module name",
    affectationDansClasses: "Assignment in classes",
    affectationEnseignants: "Teacher assignment",
    enseignants: "Teachers",
    typePunitions: "Type of punishments",
    nom: "Name",
    coefficient: "Coefficient",
    listeMatieres: "Subjects List",
    listeModules: "Modules List ",
    infoModule:
      "Assigned subjects to modules in order to organize the management of grades !",
    listeModulesSpec: "List of specific modules",
    infoModuleSpec:
      "Assigned subjects to specific modules in order to organize the management of notes!",
    ajouterModule: "Add a module ",
    ajouterMatiere: "Add a subject",
    affectes: "Affected",
    nonAffectes: " Not Affected",
    recherche: "Search",
    element: "Element",
    aucunDonnee: " No data",
    modifierListeMatieres: "Edit the list of subjects of the module : {name}",
    modifierListeEnseignants: "Edit the list of class teachers : {name}",
    supprime: "Deleted",
    charcherParClasse: "Search by class name",
    confirmDeleteModule:
      "All sessions relating to this module will be deleted. This module is used in the classrooms below",
    certification: "Certification",
    appreciation: "Appreciation",
    modulesVerySpec: "Very specific Module",
    subsubjects: "Sub-subjects",
    subsubject: "Sub-subject",
    ajouterSubsubject: "Add a sub-subject",
    addNewSubsubject: "Manage sub-subjects",
    choisirSubsubject: "Choose a sub-subject",
    mark: "Maximum mark",
    listeModulesVerySpec: "List of very specific modules",
    infoModuleVerySpec:
      "Assigned subjects to very specific modules in order to organize the management of notes!",
    totalMarksMustBe20: "{subject}: The total of sub-subject marks must be 20",

    subjectDejaExiste: "The subject already exists",
    cloneParam: "Clone a setting",
    selectParamToClone: "Select a setting to clone",
    duppModules: "Clone modules from one trimester to the other",
  },
  autorisation: {
    accueil: "Home",
    admin: "Admin",
    designation: "Designation",
    compteAdmin: " Admin Account",
    dossierMedical: "Medical report",
    listeEleves: "Students List",
    parametrage: "Settings",
    activite: "Activity",
    administration: "Administration",
    scolarite: "Schooling",
    personnel: "Personal",
    personnels: "Personals",
    comptabilite: "Accounting",
    declarations: "Declarations",
    paiements: "Payments",
    medicalNote: "Medical Note",
    medicalNoteContext:
      "Parents can view and edit their children's medical notes.",
  },

  utilisateur: {
    listeUtilisateur: "Users list",
    nom: "Name",
    role: "Role",
    acces: "Access to establishments",
    actif: "Active",
    nonActif: "Not active",
    mdp: "Password",
    activerDesactiver: "Enable/Disable",
    confirmerMdp: "Confirm Password",
    changerMdp: "You can change the password",
    active: "Active",
    nonActive: "inactive",
    desactiverAlert: "Do you want to deactivate this user?",
    activerAlert: "Do you want to activate this user?",
    userDesactive: "User disabled!",
    userActive: "User activated!",
  },

  liste: {
    listeElevesArchive: "Archived students list",
    reactive: "Reactivate",
    reasonArchive: "Reason for archiving",
    addFiles: "Add files",
    studentPhoto: "Student's avatar",
    studentFiles: "Student's files",
    consulterProfil: "Consult the profile for more details",
    nomPrenom: "Last and first name",
    scolarite: "Schooling",
    ficheParent: "Parent Sheet",
    attestation: "Presence certificate",
    inscription: "Registration",
    supprimerAnnuler: " Delete / Cancel",
    inscriSupprimer: "Registration / Delete",
    filles: "Girls",
    garcons: " Boys",
    total: "Total",
    nonInscrits: "Non-registered",
    inscrits: " Registered",
    parents: "Parents",
    ajouterEleve: " Add a student",
    ajouterDossier: "Add a document",
    ajouterPersonnel: "Add a peronal",
    ajouterEnseignant: "Add a teacher",
    listeEleves: "Students List",
    administration: "Administration",
    nom: "Name",
    prenom: "First name",
    notes: "Grades",
    dateNaissance: "Date of Birth",
    sexe: "Sex",
    adresse: "Adress",
    telephone: "Telephone",
    classes: "Classes",
    ajouterParent: "Add a  parent",
    parentsExis: "Existing parents",
    ficheParents: "Parents sheet",
    nomMere: " Mother name",
    prenomMere: "Mother last name",
    telMere: "Telephone",
    nomPere: "Father Name",
    prenomPere: "Father Last Name",
    telPere: "Telephone",
    metierPere: "Father's job",
    metierMere: "Mother's job",
    adresseParents: "Adress",
    emailParents: "E-mail ",
    reduction: "Reduction on the registration fees",
    reductionMensuel: "Discount on the monthly fees",
    identifiantUnique: "Unique identifier",
    regionalEducation: "Regional Education Commission",
    recuEn: "Received in",
    nonInscrit: "NOT ENROLLED",
    changementNonEffectue: "No change",
    champsVides: "Input fields cannot be empty",
    imprimerLoginsEnsg: "Teacher logins",
  },

  cantine: {
    modifierMenu: "Edit Menu",
    cantine: "Canteen",
    dateChoisie: "Chosen Date",
    choisirDate: "Choose a date to insert the menu",
    ajouterRepas: "Add a meal",
    ajouterMenu: " Add a new menu for ",
    choisirJour: "Choose days",
    nom: "Name",
    type: " Type",
    repeter: " Repeat ",
    saisirNom: " Enter the name of the meal",
    saisirType: "Choose the type of meal",
    plat: " Dish",
    boissons: " Drinkq",
    autre: "Others",
    dessert: "Dessert",
    menuIntrouvable: "Menu not found, please add meals!",
    ajouterNewMenu: "Add new menu for {date}",
    modifierMenu: "Edit menu {name}",
  },
  paiement: {
    reducation: "Discount",
    reducationRep: "Repetitive Discount",
    reducationDefault: "Default Discount",
    reductionBase: "Base discount",
    selectMonths: "Select months",

    avoir: "Refund",
    note: "Note",
    paiementClasse: "Payment/Class",
    nom: "Student Name",
    classe: "Class",
    parents: "Parents",
    tels: "Telephones",
    etat: "Payment Status",
    fraisInscri: "Registration Fees",
    fraisMensuel: "Monthly Fees",
    dateDebut: " Start date",
    dateFin: "End date",
    tousMois: "All months",
    mois: "Month",
    tousClasses: "All classes",
    order: "Order",
    service: "Service",
    nbEleves: "Number of students",
    listeClasses: " Classes List",
    listeEleves: "Students List",
    imprimerTab: "Print table",
    selectionnerMois: "Select month",
    paye: "Paid",
    inscriPaye: "Paid Registratiion",
    inscriNonPaye: "Unpaid Registration",
    impaye: "Unpaid",
    selectionnerDate: "Select a date",
    reduction: "Discount on registration",
    reductionMensuel: "Monthly discount",
    activite: "Activity",
    annulation: "Cancellation",
    listeActivites: "Activities List",
    dt: " TND",
    non: "No",
    chercher: "Search",
    annulerInscri: "Do you want to cancel the registration?",
    eleveRequis: "At least one student is required",
    classRequis: "At least one class is required",

    activiteRequis: "The activity is required",
    dateDebutRequis: "The start date is required",
    dateFinRequis: "The end date is required",
    msgReduction: "Set reduction to zero if you don't want to add reduction",
    typeDepense: "The type of expense is required",
    eleve: "Student is required",
  },

  inscription: {
    nom: "Fullname",
    dateNaissance: "Date of birth",
    sexe: "Sex",
    tels: "Telephone",
    parent: "Parent",
    nouvInscri: "New registration",
    nbEleves: "Number of students : {number}",
    ficheParentClasse: "Class sheet / parent",
    ficheClasse: "Class sheet",
    inscription: "Registration",
    enfant: "Child",
    fraisInscri: "Registration fees",
    fraisMensuel: "Monthly Fees",
    remise: "Discount ",
    remiseInscri: "Discount on registration",
    remiseMensuel: "Monthly Discount",
    rechercher: "Search",
    mensuel: "Monthly",
    chercherParActivite: "Search by activity name",
    sureToInscri: "Are you sure you want to register this student?",
  },

  punition: {
    createdBy: "Created by",
    eleve: "Student",
    nom: "Last Name",
    prenom: "First Name",
    details: "Details",
    punition: "Punishment",
    temps: "Time",
    raison: "Reason",
    description: "Description",
    validation: "Validation",
    valides: "Validated",
    rejetes: "Rejected",
    nonEvalues: "Not reviewed",
    dateAjout: "Added date",
    dateEvalution: "Review date",
    valider: "Validate",
    rejeter: "Reject",
    rejectReason: "The reason for the punishment rejection",
    reasonRequired: "Reason for the rejection is required",
  },

  days: {
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
  },

  emploi: {
    validated: "Schedules are now available for the class: {name}",
    unvalidated: "Schedules are now unavailable for the class: {name}",
    hideEmploin:
      "The schedule of this class will no longer be available to parents",
    validateEmploin: "The schedule of this class will be available to parents",
    emplois: "Timetables",
    temps: "Times",
    trimestre: "Trimester",
    listeClasses: "Classes List",
    listeEnseignants: "Teachers List",
    emploiEnseignants: " Teachers timetables ",
    imprimerEmploi: "  Print the timetable ",
    imprimerEmploiAdministrative: "Print the administrative timetable",
    enseignant: "Teacher",
    matiere: "Subject",
    salle: "Classroom",
    duree: "Duration",
    classe: "Class",
    choisirEnseignant: "Choose a teacher ",
    choisirMatiere: "Choose a subject",
    choisirSalle: "Choose a classroom ",
    choisirDuree: "Choose a duration",
    choisirClasse: "Choose a class",
    ajouterSeance: "Add a session",
    modifierSeance: "Edit a session ",
    disponible: "Available",
    indisponible: "Unavailable",
    modeModif: "Modification mode is activated!",
    desc: "You can modify the location and the duration of the sessions!",
    totaleHeures: "Total Hours",
    moyenneHeuresJour: "Average Hours/day",
    maximunHeuresJour: "Maximum Hours/day",
    totaleClasses: "Total classes",
    totaleTeachers: "Total teachers",
    seance: "Session",
    seanceListe: "Session list",
    choisirName: "Write a session name",
    name: "Session name",
    includedSubjects: "Teacher can access",
  },
  bulletin: {
    fuseSubSubject: "Merge sub-subjects",
    showSubModule: "Sub-module name",
    hideDate: "Hide date",
    hideSubject: "Hide subjects",
    manageCertType: "Manage certificates for :",
    alert:
      "Modification is not possible: please edit in the specific report card.",
    avg: "Module Average",
    defaultModule: "Set default modules",
    defaultModuleConfirm:
      "This action will delete the existing module. Proceed with setting the default modules?",
    defaultDone: "The modules have been updated successfully",

    coefTrimester1: "Coefficient of trimester 1 ",
    coefTrimester2: "Coefficient of trimester 2 ",
    coefTrimester3: "Coefficient of trimester 3 ",
    moyenneGeneral: "General average",
    bulletinCoefs: "Coefficients of Bulletins",
    coefBulletinsSaved:
      "The coefficients of Bulletins has been saved successfully",

    decisionConseil: "Council decision",
    finalBulletin: "Annual school report",
    syncClass: "Synchronize the classroom",
    syncSubject: "Synchronize the subject",
    syncMarks: "Synchronize the marks",
    sureToSync: "Do you want to overwrite the marks?",
    syncDone: "Marks Synchronized successfully",
    date: "Print date",
    parametrageSaved: "The bulletin settings saved successfully",
    label: "Label",
    moyennePersonalized: "Personalized average per school",
    moyenneSpecifique: "Specific average",
    moyenneVerySpecifique: "Very specific average",
    moyennePedagogique: "Pedagogical Average",
    formulePersonalized: "Personalized formula",
    imprimerFichModuleVide: "Empty sheet",
    imprimerFichModule: "Completed sheet",
    imprimerFichModuleVideAll: "All empty sheets",
    imprimerFichModuleAll: "All filled sheets",
    certByMoyenne: "Certificates according to students averages",
    imprimerBulletin: "Print the School report",
    note: "Grade",
    nom: "Student name",
    bulletin: "School report",
    bulletinV1: "School report (Old version)",
    notes: "Grades",
    matieres: "Subjects",
    bulletinPedagogique: "Pedagogic Report",
    bulletinSpecifique: "Pilote Report",
    bulletinVerySpecifique: "Specific Report",
    performancesMatiere: "Subject performance",
    plus15: "More than 15",
    plus12: "More than 12 ",
    plus9: "More than 9",
    mois9: "Under 9",

    listeClasses: "Class list",
    classesActives: "Active classes",
    nombreClassesNonNote: "Number of classes not graded",
    tousClassesSontNotes: "All classes are graded!",
    nombreMatieresNonNote: "Number of subjects not graded",
    tousMatieresSontNotes: "All subjects are graded!",
    nombreElevesNonNotes: "Number of students not graded",
    tousElevesSontNotes: "All students are graded!",

    classes: "classes",
    toutMatieres: "All the subjects",
    enregistrerTout: "Save all",

    typeIntrouvable: "Type not found",
    saisirSchoolType:
      "Please enter the type of your establishment in settings - establishment!",

    confirmPrintMatieres:
      "The subject marks below ({count}) must be completed to generate the report card!",
    confirmPrintEleves:
      "The student marks below ({count}) must be filled in to generate the report card!",
    generateAnyway: "Generate anyway",

    listeClassesNonNote: "List of ungraded classes",
    listeMatiereNonNote: "List of ungraded subjects",
    listeEleveNonNote: "List of ungraded students",

    performancesNotes: "Grades performance",
    notePlusHaute: "Highest average",
    noteEleve: "Student grade",
    notePlusBasse: "Lowest average",

    moyennesModules: "Module averages",

    maximaleMoyenne: "Maximum Average",
    moyenne: "Average",
    minimaleMoyenne: "Minimum Average",
    imprimerTout: "Print all",
    imprimerFichModuleVideexcel: "All empty sheets Excel",
    imprimerFichModuleexcel: "ALL filled sheets Excel",

    moyenneTrimestre: "trimester average : {moyenne}",
    moyenneMax: "Highest average : {moyenne}",
    moyenneMin: "Lowest average : {moyenne}",

    validated: "School report are now available for class {name}",
    unvalidated: "School report are no longer available for class {name}",
    hideBulletin: "School reports will not be available to parents",
    validateBulletin: "School reports will be available to parents",
    cancelValidation: "Make unavailable to parents",
    validatedClassroom: "Make available to parents",
    deleteMark: "Do you want to delete this mark?",
    markDeletedSucc: "Mark was successfully deleted",
    markDeletedFail: "The mark could not be deleted, please try again!",
    showStamp: "Show stamp",
    PersonalizedAverage: "Use Personalized Average",
  },

  personnel: {
    nom: "Last name",
    prenom: "First Name",
    adresse: "Adress",
    telephone: "Telephone",
    qualification: "Qualification",
    dateNaissance: "Date of birth",
    typeContrat: "Type of contrat",
    salaire: "Salary",
    cin: "CIN",
    etatCivil: "Civil status",
    niveau: "Level",
    experiences: "Experiences",
    dateEntretien: "Date of interview",
    qualifications: "Qualifications",
    tauxHoraire: "Hourly rate",
    detailPaiement: "Payment Details",
    jourSemaine: "Days",
    cnss: "CNSS",
    rib: "RIB",
    autre: "Other ",
    notes: "Grades",
    infoBase: " Basic Informations",
    infoContrat: "Contract Informations",
    chercher: "Search",
    listeQualifications: "Qualifications List",
    staff: "Staff",
  },

  enseignant: {
    advisorAddExercice: "Possibility to add exercises",
    nom: "Last Name",
    prenom: "First Name",
    adresse: "Adress",
    telephone: "Telephone",
    qualification: "Qualifications",
    dateNaissance: "Birth date",
    typeContrat: "Contrat Type",
    salaire: "Salary",
    cin: "CIN",
    etatCivil: "Civil Status",
    niveau: "Level",
    experiences: "Experiences",
    dateEntretien: "Date of interview",
    qualifications: "Qualifications",
    tauxHoraire: "Hourly rate",
    detailPaiement: "Payment Details",
    jourSemaine: "Days",
    cnss: "CNSS",
    rib: "RIB",
    autre: "Other",
    notes: "Grades",
    infoBase: " Basic Informations",
    infoContrat: "Contract Information",
    chercher: "Search",
    listeQualifications: "Qualifications List",
    matiere: "Subject ",
    specialite: "Speciality",
    grade: "Grade",
    email: "Email",
    mdp: "Password",
    compte: "Account",
    detailPedagogiques: "Pedagogical details",
    usernameEmail: "Username/email",
    photo: "Photo",
    conseillersPedagogiques: "Pedagogical advisers",
    ajouterConseillerPedagogique: "Add an advisor",
    enterInfoConseillerPedagogique:
      "Enter the advisor's information in the fields below",
    niveauxSuperviser: "Supervised levels",
    niveauxRequis: "Levels are required",
    listeNiveaux: "List of levels",
    aucunConseillerPedagogique: "No Pedagogical Advisor is found",
    needValidation: "Automatic validation of courses and exercises",
    needValidation2: " and exercises",
  },

  etatPaiement: {
    personnel: "Personal",
    salaire: "Salary",
    avance: "Advance",
    nbJours: "Number of days",
    mois: "Month",
    etatPaiement: "Payment Status",
    virementBancaire: "Bank transfer?",
    paiementAction: "Payment",
    selectionnerMois: "Select a month",
  },
  virement: {
    rib: "RIB",
    nom: "Last Name",
    prenom: "First Name",
    etatPaiement: "Payment Status",
    salaire: "Salary",
    mois: "Month",
    nbJours: "Number of days",
    chercher: "Search",
  },

  recette: {
    fournisseurProfil: "Provider profile",
    checksDueThisWeek: "Checks due this week",
    dateAjout: "Added at",
    doneBy: "Added By",
    cancelledBy: "Canceled by",
    montantCheque: "Check amount",
    montantEspece: "Cash amount",
    montantTotal: "Total amount",
    typePaiement: "Payment type",
    montant: "Amount",
    nom: "Client Name ",
    typeRecete: "Revenue type",
    typeReglement: "Payment Type",
    modePaiement: "Payment Mode",
    receiptType: "Receipt Type",
    montantHT: " HT Amount",
    montantTTC: " TTC Amount",
    type: "Type",
    datePaiement: "Date of payment",
    numCheque: "Check number",
    imprimer: "Print ",
    csv: "CSV",
    datePaiement: "Date of payment",
    tous: "All",
    cheque: "Check",
    espece: "Cash",
    chequeEtEspece: "Check and cash",
    detailsCheque: "Check Details",
    numero: "Number",
    titulaire: "Incumbent",
    banque: "Bank",
    dateEcheance: "Due date",
    chequeMontantTotal: "Total check amounts",
    relatedPayments: "Related payments",
    fermer: "Close",
    ok: "Ok",
    typeJournal: "Log type",
    selectionnerDate: "Select a date",
    motCles: "Key Words",
    chercher: "Search",
    bouillard: "Bouillard box",
    annule: "Canceled",
    personnel: "Personal",
    salaire: "Salary",
    avance: "Advance",
    nbJours: "Number of days",
    mois: "Month",
    etatPaiement: "Payment Status",
    virementBancaire: "Bank Transfer ?",
    typeDepense: "Expense Type",
    date: "Date",
    fournisseur: "Provider",
    numSerie: "Serial number",
    nomParent: "Parent Name",
    dateAnnulation: "Cancellation date",
    facture: "Invoice ?",
    impression: "Impression",
    annulation: "Cancellation",
    paiementQuoi: "Payment on what",
    selectionnerParent: "Select a parent",
    selectionnerEnfant: "Select a child",
    titre: "Title",
    valider: "Confirm",
    recuPaiement: "Payment receipt",
    facturePaiement: "payment invoice",
    paiementTotal: "Total Payment ?",
    paiementPour: "Payment for  ",
    restePayer: "Left to pay",
    payer: "Pay",
    payerMain: "Pay now ?",
    reducRepet: "Repetitive reduction",
    reducFact: "Discount On this invoice",
    reducInscri: "Discount on Registration",
    reductionMensuel: "Monthly Reduction",
    fraisInscri: "Registration fees",
    fraisMensuel: "Monthly fees ",
    ajouterPaiement: "Add a payment",
    montantRestant: "Remaining amount",
    inscription: "Registration",
    telephone: "Telephone",
    frais: "Fees",
    enfant: "Child",
    activite: "Activity",
    tousMois: "All months",
    montantHTVA: "HTVA Amount",
    nonSeule: "No (one time only)",
    payeQues: "Paid ?",
    recus: "Receipts",
    factureTag: "Invoice",
    avantReduction: "Before reduction",
    apresReduction: "After reduction",
    reduction: "Reduction",
    saisirInfoCheque: "Enter check information",
    saisirInfoChequeEtEspace: "Enter check and cash information",
    timbreFiscal: "Tax stamp",
  },

  choisir: {
    enseignant: "Choose a teacher",
    matiere: "Choose a subject",
    salle: "Choose a classroom",
    duree: "Choose a duration",
    classe: "Choose a class",
    repas: "Choose the type of meal",
    jour: "Choose the days of this meal",
    dateDebut: "Choose start date",
    dateFin: "Choose end date",
    niveauClass: "Choose class level",
    template: "Template",
  },

  requis: {
    template: "Template is Required",
    smsContenue: "The content of your message is required.",
    message: "The message is required",
    coefficient: "Coefficient is required",
    red: "Set reduction to zero if you don't want to add reduction",
    activite: "L'activité est requise",
    cnss: "CNSS is required",
    rib: "RIB is required",
    specialite: "Specialty is required",
    niveau: "Leve is required",
    etatCivil: "Marital status is required",
    jourSemaine: "Days per week are required ",
    tauxHoraire: "Hourly rate is required",
    dateEntretien: "Interview date is required",
    matiere: "The subject is required",
    typeContrat: "Contract type is required",
    qualification: "Qualification is required",
    telephone: "Phone number must be 8 digits",
    cin8: "National ID card number must be 8 digits",
    cin: "National ID card number is required",
    pourcentage: "Discount percentage is required",
    dinar: "Discount dinar is required",
    order: "Order is required",
    mail: "Enter your email!",
    mois: "Month is required",
    mdp: "Password is required",
    noWhitespace: "Field cannot contain whitespace",

    confirmation: "Password confirmation is required",
    username: " Username is required",
    usernameEmail: " Username or email is required",
    titre: "Title is required",
    categorie: "Category is required",
    designation: "Designation is required",
    cible: " Target is required",
    salaire: "Salary is required",
    role: "The role is required ",
    repas: "Enter the name of the meal",
    depense: "Expense type is required",
    fournisseur: "Provider is required",
    nom: " Name is required ",
    min: " Minimum mark is required ",
    max: " Maximum mark is required ",
    nomPersonnel: " Personnel name is required ",
    date: "Date  is required",
    selection: "Parent selection is required",
    nomPere: "Father's name is required",
    nomMere: "Mother's name is required",
    prenom: "Last name is required",
    prenomPere: "Father's first name is required",
    prenomMere: "Mother's first name is required",
    telPere: "Father's phone is required",
    telMere: "Mother's phone is required",
    email: "Invalid email ! ",
    activite: "Activity is required",
    carac: "4 characters minimum for the password ",
    genre: "Gender is required",
    adresse: "Address is required",
    service: "A service must be selected",
    fraisInscri: "Registration fees is required",
    fraisMensuel: "Monthly fees is required ",
    ajoutLogo: "Add new logo ",
    coefficient: "Coefficient is required",
    dateNaissance: "Date of birth is required",
    nbJours: "The number of days is required",
    montant: "The amount is required and must be a number",
    avance: "Advance is required",
    selectionEnfant: "Child selection is required",
    etatPaiement: "Payment status is required",
    specification: "Specification is required",
    observation: "Observation is required",
    reductionInscro:
      "Reduction on the registration fee is required (put zero if there is no reduction)",
    reductionMensuel:
      "Reduction on the monthly fee is required (enter zero if there is no reduction)",
    supp: "Are you sure to delete ?",
    supp2:
      "If you delete a student, their payment status will be updated, all unpaid months will be deleted. Safe to delete?",
    annu: "If you cancel the enrollment, Eve's enrollment status for the current school year will be updated. Sure to unsubscribe?",

    appreciation: "Appreciation is required",
  },

  success: {
    appreciationOptionAjout: "Appreciation has been added",
    appreciationOptionMSA: "Appreciation has been updated",
    appreciationOptionSupp: "Appreciation has been deleted",
    certificationAjout: "Certification has been added",
    certificationMSA: "Certification has been updated",
    certificationSupp: "Certification has been deleted",

    decisionConseilAjout: "The decision has been added",
    decisionConseilMSA: "The decision has been updated",
    decisionConseilSupp: "The decision has been deleted",

    noteEnregistre: "The grade saved successfully",
    noteToutesEnregistre: "All grades saved successfully",
    noteRemoved: "The grade has been removed successfully",
    notesRemoved: "Grades had been removed successfully",

    smsEnvoye: "SMS sent successfully",

    modificationsEnseignantsEffectue:
      "Teachers' changes are done successfully!!",
    modificationsMatieresEffectue: "Subjects' changes are done successfully!!",

    cacheAjout: "Stamp has been uploaded",
    cacheSupp: "Stamp has been deleted",
    inscriAjout: "Registration has been added",
    eleveInscrit: "students successfully registered",
    inscriSupp: "Registration has been deleted",
    inscriAnnule: "Registration has been canceled",

    pubAjout: "The post has been added",
    pubSupp: "The post has been archived",

    photoAjout: "The photo has been added",
    compte: "The account type has been added",

    autorisationMSA: "Authorization has been updated",
    autorisationSupp: "Authorization has been deleted",

    utilisateurAjout: " User has been added",
    utilisateurMSA: " User has been changed",
    utilisateurSupp: " User has been archived",
    utilisateurDesac: "User has been deactivated",
    utilisateurActiv: " User has been activated",

    repasAjout: "Meal has been added",
    repasMSA: "The meal has been changed",
    repasSupp: "The meal has been deleted",

    depenseMSA: "The expense has been updated",
    depenseSupp: "The expense has been archived",

    reglementSupp: "Payment have been removed",
    paiementAjout: "Payment have been added",
    dossierMSA: "The file has been updated",
    dossierSupp: "The file has been removed",

    buildingMSA: "The building {name} has been successfully updated",

    eleveAjout: "Student has been added",
    eleveMSA: "Student has been updated",

    bulletinMSA: " School report has been updated ",

    typeDepenseAjout: "Expense type has been added",
    typeDepenseModif: "Expense type has been updated",
    typeDepenseArchiv: "Expense type has been archived",

    fournisseurAjout: "Le fournisseur a été ajouté",
    fournisseurMSA: "Le fournisseur a été modifié",
    fournisseurArchiv: "Le fournisseur a été archivé",

    typeRecetteMSA: "Le type de recette a été modifié",
    typeRecetteAjout: "Le type de recette a été ajouté",
    typeRecetteArchiv: "Le type de recette a été archivé",

    activiteAjout: " Activity has been added",
    activiteSupp: "Activity has been archived",
    activiteMSA: "Activity has been updated",

    emploiMSA: "Schedule has been updated",

    logoMSA: "The logo has been changed ",
    fileUploadedPleaseSave: "{name} file uploaded successfully, please save!",

    seanceAjout: " The session has been added",
    seanceMSA: "The session has been updated",
    seanceSupp: " The session has been archived",

    punitionValid: " The punishment is reviewed",

    personnelAjout: " Staff has been added",
    personnelMSA: "Staff has been updated",
    personnelSupp: "Staff has been archived ",
    personnelNonSupp: "Staff has not been deleted",

    enseignantAjout: "The teacher has been added",
    enseignantMSA: "The teacher has been updated",
    enseignantSupp: "The teacher has been deleted",
    enseignantNonSupp: "The teacher was not deleted",

    classeAjout: "The class has been added",
    classeMSA: "The class has been updated",
    classeSupp: "The class has been archived",

    moduleSupp: "The module has been removed",

    typePunitionAjout: "The type of punishment has been added",
    typePunitionMSA: "The type of punishment has been updated",
    typePunitionArchiv: "The type of punishment has been archived",

    typeQualificationAjout: "The qualification type has been added",
    typeQualificationMSA: "The qualification type has been updated",
    typeQualificationArchiv: "The qualification type has been archived",

    matiereAjout: "The subject has been added",
    matiereMSA: "The subject has been updated",
    matiereSupp: "The subject has been archived",

    anneeAjout: "The school year has been added",
    anneeMSA: "The school year has been updated",
    anneeSupp: "The school year has been archived",

    serviceAjout: "The service has been added ",
    serviceMSA: "The service has been updated",
    serviceSupp: "The service has been deleted ",

    salleAjout: "The room has been added",
    salleMSA: "The room has been updated",
    salleSupp: "The room has been  archived",

    eleveAjout: "The student has been added",
    eleveMSA: "The student has been updated",
    eleveSupp: "The student has been archived",
    eleveSupp2: "The student has been deleted",
    eleveReactive: "The student has been reactivated",

    parentAjout: "The parent has been added",
    parentMSA: "The parent has been updated",
    parentSupp: "The parent has been  archived",

    eleveAjoutAuClasse: "Student successfully added to class!",
    eleveDemeangeAuClasse: "Student successfully moved to {name}",

    InscriptionAnnulee: "Registration canceled successfully.",

    imageTelecharge: "{name} file uploaded successfully.",

    conseillerPedagogiqueAjout: "The advisor has been added",
    conseillerPedagogiqueMSA: "The advisor has been updated",
    conseillerPedagogiqueSupp: "The advisor has been archived",
  },

  error: {
    impoAjoutAppreciationOption: "Unable to add appreciation",
    aucCert: "No certifications is found",
    impoAjoutCertification: "Unable to add the certification",
    impoEnregistrerVide: "Cannot save empty value",
    invalidNote: "Invalid rating!. Must be between 0 and 20",

    smsFormat: "Invalid format, phone numbers must be separated by commas!",

    eleveDejaInscritPourMois:
      "Student already registered for the selected months",

    impoModifiEleve: "Unable to update student",
    impoModifi: "Unable to update",
    eleveNonAjoutAuClasse:
      "Registration not added, please cancel the registration then assign the student to a new class!",
    InscriptionNonModifi:
      "Registration not modified, please cancel the registration and then assign the student to a new class!",

    matiereNonAffecte: "All subjects and its coefficient must be filled!",
    subsubjectNonAffecte: "{subject}: All sub-subjects must be filled!",

    capacite: "The capacity of the room is lower than the number of students",
    fichiers: "You can only upload JPG ,JPEG or PNG files!",
    fichiersPNG: "You can only upload PNG files!",
    classe: "class not found",
    activite: "Activity not found",
    service: "Service not added",
    inscription: "Registration ",
    aucScol: "No tuition registration found",
    aucClasse: "No class is found",
    aucEleve: "No student is found",
    aucInscri: "No registration for the activity is found",
    aucPersonnel: "No staff is found",
    aucService: "No serrvice is found",
    aucActivite: "No activity is found",
    aucActualite: "No news is found",
    aucRole: "No role is found",
    aucEtablissement: "No establishment is found",
    aucRecette: "No type of revenue is found",
    aucMatiere: "No subject is found",
    aucSubsubject: "No sub-subject is found",
    aucUser: "No user is found",
    aucFournisseur: "No provider is found",
    aucDepense: "No expenses are found",
    aucQualification: "No qualification type found",
    aucEnseignant: "No teacher is found",
    aucAnnee: "No school year is found",
    aucSalle: "No room is found",
    aucModule: "No module is found",
    verifier: "Check passwords",
    modif: "An error in editing",
    ajout: "An error in adding",
    supp: "An error in the deletion",

    impoInscriSupp: "Unable to delete this registration",
    impoPubAjout: "Unable to add post",
    impoPubSupp: "Unable to delete post",
    impoAjoutType: "Unable to add this type",
    impoAjoutActivite: "Unable to add activity",
    impoAjoutAnnee: "Unable to add schooler year",
    impoAjoutClasse: "Unable to add schooler class",
    impoAjoutSalle: "Unable to add the room",
    impoAjoutService: "Unable to add the service",
    impoAjoutFournisseur: "Unable to add the provider",
    impoAjoutCeType: "Unable to add this type",
    impoModifiMatieres: "Unable to edit the subjects",
    impoModifiEnseignants: "Unable to edit the teachers",

    image: "The image must be less than 5 MB",
    images50: "The images must be less than 50 MB",
    maxImage: "The maximum number of images is 15",
    nonAjoute: "Not added",

    autorisationNonMSA: "Authorization has not been updated",
    autorisationNonSupp: "Permission not removed",

    eleveNonMSA: " Student has not been updated",
    impoTelecharger: " Unable to download image",

    dossier: "Unable to find student record ",
    dossierNonMSA: "The file has not been updated",

    nonValid: "you cannot validate a payment of an amount equal to zero",
    recetteNonAjoute: "Revenue  not added",
    recetteNonMSA: "Revenue not updated ",
    recetteNonSupp: "Revenue not  deleted",

    qualificationNonAjoute: " Qualification not added",
    qualificationNonMSA: "Qualification not updated",
    qualificationNonSupp: "Qualification not deleted",

    serviceNonSupp: "The service not deleted",

    erreur: "An error has occurred",

    depenseNonMSA: "Expense not updated",
    depenseNonSupp: "Expense not deleted",

    buildingEchoue: "Failed to update building {name}",

    inscriEchoue: "Registration is failed",
    personnelNonAjoute: "Staff has not been added",
    personnelNonMSA: "Staff has not been updated",

    enseignantNonAjoute: "Teacher has not been added",
    enseignantNonMSA: "Teacher has not been updated",

    listeNonTrouve: "List of students not found",

    anneeNonAjoute: "Year has not been added",
    anneeNonMSA: "Year has not been updated",

    punitionNonAjoute: "Punishment has not been added",
    punitionNonMSA: "Punishment has not been updated",

    serviceNonAjoute: "Service has not been added",
    serviceNonMSA: "Service has not been updated",

    depenseNonAjoute: "Expense has not been added",
    depenseNonMSA: "Expense has not been updated",

    fournisseurNonAjoute: "Provider has not been added",
    fournisseurNonMSA: "Provider has not been updated",
    fournisseurNonSupp: "Provider has not been deleted",

    activiteNonMSA: "L'activité ne peut être modifé, veuillez réessayer!",

    moduleNonAjoute: "Module has not been added",
    moduleNonMSA: "Module has not been updated",
    personnelNonMSA: "Staff has not been updated!",
    personnelNonAjoute: "Staff not added",
    matiereNonAjoute: "Subject has not been added",
    matiereNonMSA: "Subject has not been updated",
    listeNonTrouve: "List of students not found!",
    inscrEchoue: "Registration is failed",
    activiteNonSupp: "Activity cannot be deleted",
    classeNonSupp: "Class cannot be deleted",
    classeIndis: "The class is unavailable ",
    salleIndis: "The room is unavailable ",
    enseignantIndis: "The teacher is unavailable",
    selectEnseignant: "Select a teacher",
    selectSeance: "Select a session",
    selectClasse: "Select a class ",
    sallePriseEntre:
      "The room {room} is taken by the class {name} between {start} and {end}",
    classPriseEntre: "The class {name} is taking between {start} and {end}",
    enseignantEnseigneClasse:
      "Teacher {fullname} teaches class {name} subject {subject} between {start} and {end}",

    anneeNonSupp: "Year cannot be deleted",

    utilisateurNonAjoutDiff:
      "Cannot add user, please try with a different username!",

    conseillerPedagogiqueNonAjoute: "Pedagogical advisor not added",
    conseillerPedagogiqueNonMSA: "Pedagogical advisor has not been updated",
    conseillerPedagogiqueNonSupp: "Pedagogical advisor has not been removed",
    invalidFormat: "Invalid File Format",
  },

  warning: {
    verifieDonnees: "Check your data",
    connectionProblem: "A connection problem, please refresh the page!",
    selectModePaiement: "Select payment method",
    paiementNonEffectuer: "Payment not made",
    invalidMarks: "Please ensure your marks fall within the range of 0 to 20",
    notCorrectMarks:
      "Please ensure that your marks have been entered correctly",
    missedSubsubjects:
      "It appears that not all of ({name})'s marks have been completed. Please fill in any missing marks.",
  },
};

export default {
  locale: "en-US",
  localeAntd,
  messages,
};
