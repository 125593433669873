<template>
  <div :class="$style.topbar">
    <div class="mr-4">
      <cui-fav-pages />
    </div>
    <div class="mr-auto">
      <cui-select-building />
    </div>
    <div class="ml-4 mr-auto">
      <CuiSelectSchoolarYear />
    </div>
    <div class="mr-4 d-none d-md-block"></div>
    <div class="mb-0 mr-auto d-xl-block d-none"></div>
    <div class="mr-4 d-none d-sm-block">
      <!--if a client want to manage his website link to button-->
      <!--  <a
        href="http://nmsgroupe.tn/admin/login.php"
        target="_blank"
        rel="noopener noreferrer"
      >
        <a-button type="primary" icon="global">
          Gérer vote site web
        </a-button></a
      >-->
    </div>

    <!-- start notification section -->
    <div class="mr-3 d-none d-sm-block">
      <cui-notification-menu />
    </div>
    <!-- end notification section -->

    <div v-if="verifyAccess(['all', 'chat'])" class="mr-4 d-none d-sm-block">
      <a-tooltip placement="bottom" title="chat">
        <a-badge :count="unreadedMessages" :overflow-count="10">
          <a-button
            @click="chatPage"
            shape="circle"
            class="mt-1"
            icon="wechat"
          />
        </a-badge>
      </a-tooltip>
    </div>
    <div class="mr-4 d-none d-sm-block">
      <cui-language-switcher />
    </div>
    <div>
      <cui-user-menu />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import CuiLanguageSwitcher from "./LanguageSwitcher";
import CuiNotificationMenu from "./NotificationMenu";
import CuiUserMenu from "./UserMenu";
import CuiFavPages from "./FavPages";
import CuiSelectBuilding from "./SelectBuilding";
import CuiSelectSchoolarYear from "./SelectSchoolarYear";
import store from "store";
import apiClient from "@/services/axios";
import { mapState } from "vuex";

export default {
  data() {
    return {
      unreadedMessages: 0,
      test: false,
      titleLoop: null,
    };
  },
  components: {
    CuiUserMenu,
    CuiFavPages,
    CuiSelectBuilding,
    CuiSelectSchoolarYear,
    CuiLanguageSwitcher,
    CuiNotificationMenu,
  },
  created() {
    if (this.verifyAccess(["all", "chat"])) {
      apiClient.get("/chat/admin/unseenMessages").then((res) => {
        res.data.map((parent) => {
          this.unreadedMessages += parent.count;
        });
      });

      this.$socket.client.emit("adminJoin", {
        token: store.get("accessToken"),
        db: store.get("DB"),
      });

      this.$socket.client.on("adminJoined", (data) => {
        // joined the room
      });

      this.$socket.client.on("messageNotification", (data) => {
        if (this.$router.currentRoute.path != "/chat") {
          this.unreadedMessages++;
          this.newNotify();
        }
        const audio = new Audio("/resources/notification.mp3");
        audio.play();
      });
    }
  },

  computed: {
    ...mapState(["settings", "user"]),
    schoolDetails() {
      return (
        this.user.building.filter(
          (el) => el.dbName == this.settings.activeBuilding
        )[0] || {}
      );
    },
  },

  methods: {
    chatPage() {
      this.unreadedMessages = 0;
      this.resetNotification();
      this.$router.push("/chat");
    },
    newNotify() {
      clearInterval(this.titleLoop);
      this.titleLoop = setInterval(() => {
        if (this.test) document.title = "Système de gestion";
        else document.title = "(" + this.unreadedMessages + ") " + "Messages";
        this.test = !this.test;
      }, 1500);
    },

    resetNotification() {
      clearInterval(this.titleLoop);
      document.title = "Système de gestion";
    },

    verifyAccess(accessRoles) {
      const existing = this.user.access;
      if (!this.schoolDetails.access.edManager["chat"]) return false;
      if (existing.all) return true;
      if (
        existing.chat &&
        existing.chat.length > 0 &&
        existing.chat.find((e) => accessRoles.includes(e))
      )
        return true;
      else return false;
    },
  },
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
